export default function Alert({ children, type }) {
  let colors = {
    bg: 'bg-green-50',
    text: 'text-green-800',
  };

  if (type === 'danger') {
    colors = {
      bg: 'bg-red-50',
      text: 'text-red-800',
    };
  }

  if (type === 'warning') {
    colors = {
      bg: 'bg-yellow-50',
      text: 'text-yellow-800',
    };
  }

  if (type === 'info') {
    colors = {
      bg: 'bg-blue-50',
      text: 'text-blue-800',
    };
  }

  return (
    <div className={`p-4 rounded-md ${colors.bg}`}>
      <div className='flex'>
        <p className={`text-sm font-medium ${colors.text}`}>{children}</p>
      </div>
    </div>
  );
}
